import action from "../actionTypes/driver";

const initialState = {
  driverDetailsForm: {},
  driverAddressDetailsForm: [],
  driverAdditionalDetailsForm: [],
  driverAttachmentsDetails: [],
  driverLicenseDetailsForm: [],
  driverQualificationDetailsForm: [],
  driverDigiCardForm: [],
  driverPayrollForm: [],
  driverPreferencesForm: [],
  driverWorkHistoryForm: [],
  driverHumanResourceForm: [],
  candidateRatingForm: [],
  candidateInfringementForm: [],
  candidateNotesForm: [],
  candidatePerformanceForm: [],
  candidateTasksForm: [],
  candidateSmartBoxForm: []
};

const driverFormsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case action.DRIVER_DETAILS_FORM:
      return { ...state, driverDetailsForm: payload };
    case action.DRIVER_ADDRESS_DETAILS_FORM:
      return { ...state, driverAddressDetailsForm: payload };
    case action.DRIVER_ADDITIONAL_DETAILS_FORM:
      return { ...state, driverAdditionalDetailsForm: payload };
    case action.DRIVER_ATTACHMENT_DETAILS:
      return { ...state, driverAttachmentsDetails: payload };
    case action.DRIVER_LICENSE_DETAILS_FORM:
      return { ...state, driverLicenseDetailsForm: payload };
    case action.DRIVER_QUALIFICATION_DETAILS_FORM:
      return { ...state, driverQualificationDetailsForm: payload };
    case action.DRIVER_PAYROLL_FORMS:
      return { ...state, driverPayrollForm: payload };
    case action.DRIVER_PREFERENCE_FORMS:
      return { ...state, driverPreferencesForm: payload };
    case action.DRIVER_WORK_HISTORY:
      return { ...state, driverWorkHistoryForm: payload };
    case action.DRIVER_HUMAN_RESOURCE:
      return { ...state, driverHumanResourceForm: payload };
    case action.DRIVER_RATINGS_FORMS:
      return { ...state, candidateRatingForm: payload };
    case action.DRIVER_INFRINGEMENTS_FORMS:
      return { ...state, candidateInfringementForm: payload };
    case action.DRIVER_NOTES_FORMS:
      return { ...state, candidateNotesForm: payload };
    case action.DRIVER_PERFORMANCE_FORMS:
      return { ...state, candidatePerformanceForm: payload };
    case action.DRIVER_TASKS_FORMS:
      return { ...state, candidateTasksForm: payload };
    case action.DRIVER_SMART_BOX_FORM:
      return { ...state, candidateSmartBoxForm: payload };
    case action.DRIVER_RESET_FORMS:
      return { ...initialState };
    default:
      return state;
  }
};

export default driverFormsReducer;
