import { combineReducers } from "redux";
import menuReducer from "./menuReducer";
import apiMessageReducer from "./apiMessageReducer";
import candidateFilter from "./candidateFilterReducer";
import candidateReducer from "./candidateReducer";
import userReducer from "./userReducer";
import candidateModalReducer from "./candidateModalReducer";
import driverReducer from "./driverReducer";
import siteFilter from "./siteListFilterReducer";
import driverFormsReducer from "./driverFormsReducer";
import calendarReducer from "./calendarReducer";
import siteModalReducer from "./siteModalReducer";
import bookingReducer from "./bookingReducer";
import clientReducer from "./clientReducer";
import financeReducer from "./financeReducer";
import bookingSetupReducer from "./bookingSetupReducer";
import supplierReducer from "./supplierReducer";
import financeFilterReducer from "./financeFilterReducer";
import draggableModalReducer from "./draggableModalReducer";

const RootReducer = combineReducers({
  apiMessageReducer,
  userReducer,
  candidateReducer,
  candidateModalReducer,
  driverReducer,
  candidateFilter,
  financeFilterReducer,
  siteFilter,
  driverFormsReducer,
  draggableModalReducer,
  calendarReducer,
  siteModalReducer,
  bookingReducer,
  menuReducer,
  clientReducer,
  financeReducer,
  bookingSetupReducer,
  supplierReducer
});

export default RootReducer;
