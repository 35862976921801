import keyMirror from "keymirror";

const actions = {
  LOGIN_IN: null,
  LOGOUT_USER: null,
  MENU_CLICK: null,

  API_SUCCESS_MSG: null,
  API_FAILURE_MSG: null,
  API_MSG_CLEAR: null,

  GLOBAL_SEARCH: null,
  GLOBAL_SEARCH_SUCCESS: null,
  GLOBAL_SEARCH_ERROR: null,
  GLOBAL_SEARCH_CLEAR: null,

  DRAGGABLE_MODAL_STATE: null,

  ATTACH_NEW_CANDIDATE: null,
  ATTACH_NEW_CANDIDATE_SUCCESS: null,
  ATTACH_NEW_CANDIDATE_ERROR: null,
  ATTACH_NEW_CANDIDATE_CLEAR: null,

  CANDIDATE_LISTING: null,
  CANDIDATE_LISTING_SUCCESS: null,
  CANDIDATE_LISTING_ERROR: null,
  CANDIDATE_LISTING_CLEAR: null,

  CANDIDATE_LISTING_PAGED: null,
  CANDIDATE_LISTING_PAGED_SUCCESS: null,
  CANDIDATE_LISTING_PAGED_ERROR: null,
  CANDIDATE_LISTING_PAGED_CLEAR: null,

  CANDIDATE_INVITE_APP: null,
  CANDIDATE_INVITE_APP_SUCCESS: null,
  CANDIDATE_INVITE_APP_ERROR: null,
  CANDIDATE_INVITE_APP_CLEAR: null,

  INVITE_E_SIGN_CANDIDATES: null,
  INVITE_E_SIGN_CANDIDATES_SUCCESS: null,
  INVITE_E_SIGN_CANDIDATES_ERROR: null,
  INVITE_E_SIGN_CANDIDATES_CLEAR: null,

  CANDIDATE_DUPLICATES: null,
  CANDIDATE_DUPLICATES_SUCCESS: null,
  CANDIDATE_DUPLICATES_ERROR: null,
  CANDIDATE_DUPLICATES_CLEAR: null,

  CANDIDATE_DELETE: null,
  CANDIDATE_DELETE_SUCCESS: null,
  CANDIDATE_DELETE_ERROR: null,
  CANDIDATE_DELETE_CLEAR: null,

  DELETE_USER: null,
  DELETE_USER_SUCCESS: null,
  DELETE_USER_ERROR: null,
  DELETE_USER_CLEAR: null,

  EDIT_USER: null,
  EDIT_USER_SUCCESS: null,
  EDIT_USER_ERROR: null,
  EDIT_USER_CLEAR: null,

  PERSON_DELETE: null,
  PERSON_DELETE_SUCCESS: null,
  PERSON_DELETE_ERROR: null,
  PERSON_DELETE_CLEAR: null,

  PERSON_DELETE_MERGE: null,
  PERSON_DELETE_MERGE_SUCCESS: null,
  PERSON_DELETE_MERGE_ERROR: null,
  PERSON_DELETE_MERGE_CLEAR: null,

  CANDIDATE_MERGE_DELETE: null,
  CANDIDATE_MERGE_DELETE_SUCCESS: null,
  CANDIDATE_MERGE_DELETE_ERROR: null,
  CANDIDATE_MERGE_DELETE_CLEAR: null,

  ADD_AVAILABILITY: null,
  ADD_AVAILABILITY_SUCCESS: null,
  ADD_AVAILABILITY_ERROR: null,
  ADD_AVAILABILITY_CLEAR: null,

  ADD_TIMEOFF: null,
  ADD_TIMEOFF_SUCCESS: null,
  ADD_TIMEOFF_ERROR: null,
  ADD_TIMEOFF_CLEAR: null,

  REMOVE_AVAILABILITY: null,
  REMOVE_AVAILABILITY_SUCCESS: null,
  REMOVE_AVAILABILITY_ERROR: null,
  REMOVE_AVAILABILITY_CLEAR: null,

  DELETE_TIMEOFF: null,
  DELETE_TIMEOFF_SUCCESS: null,
  DELETE_TIMEOFF_ERROR: null,
  DELETE_TIMEOFF_CLEAR: null,

  DRIVER_CALENDAR: null,
  DRIVER_CALENDAR_SUCCESS: null,
  DRIVER_CALENDAR_ERROR: null,
  DRIVER_CALENDAR_CLEAR: null,

  RIBBON_DATA: null,
  RIBBON_DATA_SUCCESS: null,
  RIBBON_DATA_ERROR: null,
  RIBBON_DATA_CLEAR: null,

  CANDIDATE_TYPE: null,
  CANDIDATE_TYPE_SUCCESS: null,
  CANDIDATE_TYPE_ERROR: null,
  CANDIDATE_TYPE_CLEAR: null,

  CANDIDATE_ASSIGN_LIST: null,
  CANDIDATE_ASSIGN_LIST_SUCCESS: null,
  CANDIDATE_ASSIGN_LIST_ERROR: null,
  CANDIDATE_ASSIGN_LIST_CLEAR: null,

  SINGLE_CANDIDATE_ASSIGN_LIST: null,
  SINGLE_CANDIDATE_ASSIGN_LIST_SUCCESS: null,
  SINGLE_CANDIDATE_ASSIGN_LIST_ERROR: null,
  SINGLE_CANDIDATE_ASSIGN_LIST_CLEAR: null,

  CHANGE_CANDIDATE_STATUS: null,
  CHANGE_CANDIDATE_STATUS_SUCCESS: null,
  CHANGE_CANDIDATE_STATUS_ERROR: null,
  CHANGE_CANDIDATE_STATUS_CLEAR: null,

  SUPPLIER_CANDIDATE_LIST: null,
  SUPPLIER_CANDIDATE_LIST_SUCCESS: null,
  SUPPLIER_CANDIDATE_LIST_ERROR: null,
  SUPPLIER_CANDIDATE_LIST_CLEAR: null,

  CANDIDATE_WORKING_TIME: null,
  CANDIDATE_WORKING_TIME_SUCCESS: null,
  CANDIDATE_WORKING_TIME_ERROR: null,
  CANDIDATE_WORKING_TIME_CLEAR: null,

  CANDIDATE_EMAIL: null,
  CANDIDATE_EMAIL_SUCCESS: null,
  CANDIDATE_EMAIL_ERROR: null,
  CANDIDATE_EMAIL_CLEAR: null,

  CANDIDATE_EMAIL_LIST: null,
  CANDIDATE_EMAIL_LIST_SUCCESS: null,
  CANDIDATE_EMAIL_LIST_ERROR: null,
  CANDIDATE_EMAIL_LIST_CLEAR: null,

  EMAIL_LIST: null,
  EMAIL_LIST_SUCCESS: null,
  EMAIL_LIST_ERROR: null,
  EMAIL_LIST_CLEAR: null,

  CLIENT_LIST: null,
  CLIENT_LIST_SUCCESS: null,
  CLIENT_LIST_ERROR: null,
  CLIENT_LIST_CLEAR: null,

  LOGIN: null,
  LOGIN_SUCCESS: null,
  LOGIN_ERROR: null,
  LOGIN_CLEAR: null,

  USER_LIST: null,
  USER_LIST_SUCCESS: null,
  USER_LIST_ERROR: null,
  USER_LIST_CLEAR: null,

  USER_LIST_PAGED: null,
  USER_LIST_PAGED_SUCCESS: null,
  USER_LIST_PAGED_ERROR: null,
  USER_LIST_PAGED_CLEAR: null,

  MASS_RESET_PW: null,
  MASS_RESET_PW_SUCCESS: null,
  MASS_RESET_PW_ERROR: null,
  MASS_RESET_PW_CLEAR: null,

  SITE_LIST: null,
  SITE_LIST_SUCCESS: null,
  SITE_LIST_ERROR: null,
  SITE_LIST_CLEAR: null,

  SITE_LIST_EMAIL: null,
  SITE_LIST_EMAIL_SUCCESS: null,
  SITE_LIST_EMAIL_ERROR: null,
  SITE_LIST_EMAIL_CLEAR: null,

  SITE_LIST_EMAIL_LIST: null,
  SITE_LIST_EMAIL_LIST_SUCCESS: null,
  SITE_LIST_EMAIL_LIST_ERROR: null,
  SITE_LIST_EMAIL_LIST_CLEAR: null,

  SITE_LIST_SMS: null,
  SITE_LIST_SMS_SUCCESS: null,
  SITE_LIST_SMS_ERROR: null,
  SITE_LIST_SMS_CLEAR: null,

  SITE_LIST_SMS_LIST: null,
  SITE_LIST_SMS_LIST_SUCCESS: null,
  SITE_LIST_SMS_LIST_ERROR: null,
  SITE_LIST_SMS_LIST_CLEAR: null,

  LEAD_SOURCE_LIST: null,
  LEAD_SOURCE_LIST_SUCCESS: null,
  LEAD_SOURCE_LIST_ERROR: null,
  LEAD_SOURCE_LIST_CLEAR: null,

  INSURANCE_LIST: null,
  INSURANCE_LIST_SUCCESS: null,
  INSURANCE_LIST_ERROR: null,
  INSURANCE_LIST_CLEAR: null,

  NATIONALITY_LIST: null,
  NATIONALITY_LIST_SUCCESS: null,
  NATIONALITY_LIST_ERROR: null,
  NATIONALITY_LIST_CLEAR: null,

  ADD_NEW_CANDIDATE: null,
  ADD_NEW_CANDIDATE_SUCCESS: null,
  ADD_NEW_CANDIDATE_ERROR: null,
  ADD_NEW_CANDIDATE_CLEAR: null,

  ADD_NEW_ADDRESS: null,
  ADD_NEW_ADDRESS_SUCCESS: null,
  ADD_NEW_ADDRESS_ERROR: null,
  ADD_NEW_ADDRESS_CLEAR: null,

  ADD_NEW_ADDITIONAL_ADDRESS: null,
  ADD_NEW_ADDITIONAL_ADDRESS_SUCCESS: null,
  ADD_NEW_ADDITIONAL_ADDRESS_ERROR: null,
  ADD_NEW_ADDITIONAL_ADDRESS_CLEAR: null,

  ADD_MULTIPLE_DOCUMENTS: null,
  ADD_MULTIPLE_DOCUMENTS_SUCCESS: null,
  ADD_MULTIPLE_DOCUMENTS_ERROR: null,
  ADD_MULTIPLE_DOCUMENTS_CLEAR: null,

  ADD_LICENSE_INFORMATION: null,
  ADD_LICENSE_INFORMATION_SUCCESS: null,
  ADD_LICENSE_INFORMATION_ERROR: null,
  ADD_LICENSE_INFORMATION_CLEAR: null,

  DRIVING_LICENSE_CAPABILITY_LIST: null,
  DRIVING_LICENSE_CAPABILITY_LIST_SUCCESS: null,
  DRIVING_LICENSE_CAPABILITY_LIST_ERROR: null,
  DRIVING_LICENSE_CAPABILITY_LIST_CLEAR: null,

  DRIVING_LICENSE_ENDORSEMENT_LIST: null,
  DRIVING_LICENSE_ENDORSEMENT_LIST_SUCCESS: null,
  DRIVING_LICENSE_ENDORSEMENT_LIST_ERROR: null,
  DRIVING_LICENSE_ENDORSEMENT_LIST_CLEAR: null,

  DRIVING_LICENSE_CAPABILITY_TYPES_LIST: null,
  DRIVING_LICENSE_CAPABILITY_TYPES_LIST_SUCCESS: null,
  DRIVING_LICENSE_CAPABILITY_TYPES_LIST_ERROR: null,
  DRIVING_LICENSE_CAPABILITY_TYPES_LIST_CLEAR: null,

  DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST: null,
  DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST_SUCCESS: null,
  DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST_ERROR: null,
  DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST_CLEAR: null,

  ADD_INDUCTIONS_BANS_DETAILS: null,
  ADD_INDUCTIONS_BANS_DETAILS_SUCCESS: null,
  ADD_INDUCTIONS_BANS_DETAILS_ERROR: null,
  ADD_INDUCTIONS_BANS_DETAILS_CLEAR: null,

  QUALIFICATION_TITLE_LIST: null,
  QUALIFICATION_TITLE_LIST_SUCCESS: null,
  QUALIFICATION_TITLE_LIST_ERROR: null,
  QUALIFICATION_TITLE_LIST_CLEAR: null,

  QUALIFICATION_NAME_LIST: null,
  QUALIFICATION_NAME_LIST_SUCCESS: null,
  QUALIFICATION_NAME_LIST_ERROR: null,
  QUALIFICATION_NAME_LIST_CLEAR: null,

  ADD_QUALIFICATION_DETAILS: null,
  ADD_QUALIFICATION_DETAILS_SUCCESS: null,
  ADD_QUALIFICATION_DETAILS_ERROR: null,
  ADD_QUALIFICATION_DETAILS_CLEAR: null,

  ADD_LICENCE_ADDITIONAL_DETAILS: null,
  ADD_LICENCE_ADDITIONAL_DETAILS_SUCCESS: null,
  ADD_LICENCE_ADDITIONAL_DETAILS_ERROR: null,
  ADD_LICENCE_ADDITIONAL_DETAILS_CLEAR: null,

  PAYMENT_TIER_LIST: null,
  PAYMENT_TIER_LIST_SUCCESS: null,
  PAYMENT_TIER_LIST_ERROR: null,
  PAYMENT_TIER_LIST_CLEAR: null,

  SUPPLIER_LIST: null,
  SUPPLIER_LIST_SUCCESS: null,
  SUPPLIER_LIST_ERROR: null,
  SUPPLIER_LIST_CLEAR: null,

  SERVICE_COMPANY_LIST: null,
  SERVICE_COMPANY_LIST_SUCCESS: null,
  SERVICE_COMPANY_LIST_ERROR: null,
  SERVICE_COMPANY_LIST_CLEAR: null,

  CONTACT_TYPE_LIST: null,
  CONTACT_TYPE_LIST_SUCCESS: null,
  CONTACT_TYPE_LIST_ERROR: null,
  CONTACT_TYPE_LIST_CLEAR: null,

  EXPENSE_TYPE_LIST: null,
  EXPENSE_TYPE_LIST_SUCCESS: null,
  EXPENSE_TYPE_LIST_ERROR: null,
  EXPENSE_TYPE_LIST_CLEAR: null,

  ADD_PAYROLL_DETAILS: null,
  ADD_PAYROLL_DETAILS_SUCCESS: null,
  ADD_PAYROLL_DETAILS_ERROR: null,
  ADD_PAYROLL_DETAILS_CLEAR: null,

  ADD_PREFERENCE_DETAILS: null,
  ADD_PREFERENCE_DETAILS_SUCCESS: null,
  ADD_PREFERENCE_DETAILS_ERROR: null,
  ADD_PREFERENCE_DETAILS_CLEAR: null,

  ADD_WORK_HISTORY: null,
  ADD_WORK_HISTORY_SUCCESS: null,
  ADD_WORK_HISTORY_ERROR: null,
  ADD_WORK_HISTORY_CLEAR: null,

  SATISFACTION_LEVEL_LIST: null,
  SATISFACTION_LEVEL_LIST_SUCCESS: null,
  SATISFACTION_LEVEL_LIST_ERROR: null,
  SATISFACTION_LEVEL_LIST_CLEAR: null,

  REASONS_LIST: null,
  REASONS_LIST_SUCCESS: null,
  REASONS_LIST_ERROR: null,
  REASONS_LIST_CLEAR: null,

  NON_CONVERSION_LIST: null,
  NON_CONVERSION_LIST_SUCCESS: null,
  NON_CONVERSION_LIST_ERROR: null,
  NON_CONVERSION_LIST_CLEAR: null,

  ADD_HUMAN_RESOURCE: null,
  ADD_HUMAN_RESOURCE_SUCCESS: null,
  ADD_HUMAN_RESOURCE_ERROR: null,
  ADD_HUMAN_RESOURCE_CLEAR: null,

  RATING_TYPE_LIST: null,
  RATING_TYPE_LIST_SUCCESS: null,
  RATING_TYPE_LIST_ERROR: null,
  RATING_TYPE_LIST_CLEAR: null,

  ADD_CANDIDATE_RATINGS: null,
  ADD_CANDIDATE_RATINGS_SUCCESS: null,
  ADD_CANDIDATE_RATINGS_ERROR: null,
  ADD_CANDIDATE_RATINGS_CLEAR: null,

  ADD_NEW_USER: null,
  ADD_NEW_USER_SUCCESS: null,
  ADD_NEW_USER_ERROR: null,
  ADD_NEW_USER_CLEAR: null,

  GET_USER_SITES: null,
  GET_USER_SITES_SUCCESS: null,
  GET_USER_SITES_ERROR: null,
  GET_USER_SITES_CLEAR: null,

  CHANGE_PASSWORD: null,
  CHANGE_PASSWORD_SUCCESS: null,
  CHANGE_PASSWORD_ERROR: null,
  CHANGE_PASSWORD_CLEAR: null,

  INFRINGEMENTS_TYPE_LIST: null,
  INFRINGEMENTS_TYPE_LIST_SUCCESS: null,
  INFRINGEMENTS_TYPE_LIST_ERROR: null,
  INFRINGEMENTS_TYPE_LIST_CLEAR: null,

  ADD_CANDIDATE_INFRINGEMENTS: null,
  ADD_CANDIDATE_INFRINGEMENTS_SUCCESS: null,
  ADD_CANDIDATE_INFRINGEMENTS_ERROR: null,
  ADD_CANDIDATE_INFRINGEMENTS_CLEAR: null,

  CALENDAR_BOOKING_OPERATION: null,
  CALENDAR_BOOKING_OPERATION_SUCCESS: null,
  CALENDAR_BOOKING_OPERATION_ERROR: null,
  CALENDAR_BOOKING_OPERATION_CLEAR: null,

  UPDATE_BOOKING_DETAILS_DATA: null,
  UPDATE_BOOKING_DETAILS_DATA_SUCCESS: null,
  UPDATE_BOOKING_DETAILS_DATA_ERROR: null,
  UPDATE_BOOKING_DETAILS_DATA_CLEAR: null,

  CONFIRM_ALL_BOOKING_OPERATION: null,
  CONFIRM_ALL_BOOKING_OPERATION_SUCCESS: null,
  CONFIRM_ALL_BOOKING_OPERATION_ERROR: null,
  CONFIRM_ALL_BOOKING_OPERATION_CLEAR: null,

  HOLIDAYS_LIST: null,
  HOLIDAYS_LIST_SUCCESS: null,
  HOLIDAYS_LIST_ERROR: null,
  HOLIDAYS_LIST_CLEAR: null,

  NOTES_LIST: null,
  NOTES_LIST_SUCCESS: null,
  NOTES_LIST_ERROR: null,
  NOTES_LIST_CLEAR: null,

  ADD_NOTES_ACTIVITY: null,
  ADD_NOTES_ACTIVITY_SUCCESS: null,
  ADD_NOTES_ACTIVITY_ERROR: null,
  ADD_NOTES_ACTIVITY_CLEAR: null,

  STATEMENT_OPTIONS_LIST: null,
  STATEMENT_OPTIONS_LIST_SUCCESS: null,
  STATEMENT_OPTIONS_LIST_ERROR: null,
  STATEMENT_OPTIONS_LIST_CLEAR: null,

  PERFORMANCE_TYPE_LIST: null,
  PERFORMANCE_TYPE_LIST_SUCCESS: null,
  PERFORMANCE_TYPE_LIST_ERROR: null,
  PERFORMANCE_TYPE_LIST_CLEAR: null,

  ADD_PERFORMANCE_ACTIVITY: null,
  ADD_PERFORMANCE_ACTIVITY_SUCCESS: null,
  ADD_PERFORMANCE_ACTIVITY_ERROR: null,
  ADD_PERFORMANCE_ACTIVITY_CLEAR: null,

  TASKS_LIST: null,
  TASKS_LIST_SUCCESS: null,
  TASKS_LIST_ERROR: null,
  TASKS_LIST_CLEAR: null,

  ADD_TASKS_ACTIVITY: null,
  ADD_TASKS_ACTIVITY_SUCCESS: null,
  ADD_TASKS_ACTIVITY_ERROR: null,
  ADD_TASKS_ACTIVITY_CLEAR: null,

  SMS_HISTORY_LIST: null,
  SMS_HISTORY_LIST_SUCCESS: null,
  SMS_HISTORY_LIST_ERROR: null,
  SMS_HISTORY_LIST_CLEAR: null,

  SEND_SMS: null,
  SEND_SMS_SUCCESS: null,
  SEND_SMS_ERROR: null,
  SEND_SMS_CLEAR: null,

  EMAIL_HISTORY_LIST: null,
  EMAIL_HISTORY_LIST_SUCCESS: null,
  EMAIL_HISTORY_LIST_ERROR: null,
  EMAIL_HISTORY_LIST_CLEAR: null,

  ADD_SMART_BOX_ACTIVITY: null,
  ADD_SMART_BOX_ACTIVITY_SUCCESS: null,
  ADD_SMART_BOX_ACTIVITY_ERROR: null,
  ADD_SMART_BOX_ACTIVITY_CLEAR: null,

  VIEW_SITE_LOCATION: null,
  VIEW_SITE_LOCATION_SUCCESS: null,
  VIEW_SITE_LOCATION_ERROR: null,
  VIEW_SITE_LOCATION_CLEAR: null,

  SITE_DAYS_OF_WEEK: null,
  SITE_DAYS_OF_WEEK_SUCCESS: null,
  SITE_DAYS_OF_WEEK_ERROR: null,
  SITE_DAYS_OF_WEEK_CLEAR: null,

  ADD_SITE_GENERAL_DETAILS: null,
  ADD_SITE_GENERAL_DETAILS_SUCCESS: null,
  ADD_SITE_GENERAL_DETAILS_ERROR: null,
  ADD_SITE_GENERAL_DETAILS_CLEAR: null,

  SITE_DEFAULT_SHIFT_TYPE: null,
  SITE_DEFAULT_SHIFT_TYPE_SUCCESS: null,
  SITE_DEFAULT_SHIFT_TYPE_ERROR: null,
  SITE_DEFAULT_SHIFT_TYPE_CLEAR: null,

  ADD_SITE_CONTACT_FORM: null,
  ADD_SITE_CONTACT_FORM_SUCCESS: null,
  ADD_SITE_CONTACT_FORM_ERROR: null,
  ADD_SITE_CONTACT_FORM_CLEAR: null,

  SITE_DOCUMENT: null,
  SITE_DOCUMENT_SUCCESS: null,
  SITE_DOCUMENT_ERROR: null,
  SITE_DOCUMENT_CLEAR: null,

  ADD_SITE_DOCUMENT: null,
  ADD_SITE_DOCUMENT_SUCCESS: null,
  ADD_SITE_DOCUMENT_ERROR: null,
  ADD_SITE_DOCUMENT_CLEAR: null,

  RATING_DETAILS_LIST: null,
  RATING_DETAILS_LIST_SUCCESS: null,
  RATING_DETAILS_LIST_ERROR: null,
  RATING_DETAILS_LIST_CLEAR: null,

  INFRINGEMENTS_DETAILS_LIST: null,
  INFRINGEMENTS_DETAILS_LIST_SUCCESS: null,
  INFRINGEMENTS_DETAILS_LIST_ERROR: null,
  INFRINGEMENTS_DETAILS_LIST_CLEAR: null,

  PERFORMANCE_DETAILS_LIST: null,
  PERFORMANCE_DETAILS_LIST_SUCCESS: null,
  PERFORMANCE_DETAILS_LIST_ERROR: null,
  PERFORMANCE_DETAILS_LIST_CLEAR: null,

  SMART_BOX_DETAILS_LIST: null,
  SMART_BOX_DETAILS_LIST_SUCCESS: null,
  SMART_BOX_DETAILS_LIST_ERROR: null,
  SMART_BOX_DETAILS_LIST_CLEAR: null,

  UPDATE_CANDIDATE_RATING: null,
  UPDATE_CANDIDATE_RATING_SUCCESS: null,
  UPDATE_CANDIDATE_RATING_ERROR: null,
  UPDATE_CANDIDATE_RATING_CLEAR: null,

  UPDATE_CANDIDATE_INFRINGEMENT: null,
  UPDATE_CANDIDATE_INFRINGEMENT_SUCCESS: null,
  UPDATE_CANDIDATE_INFRINGEMENT_ERROR: null,
  UPDATE_CANDIDATE_INFRINGEMENT_CLEAR: null,

  UPDATE_CANDIDATE_PERFORMANCE: null,
  UPDATE_CANDIDATE_PERFORMANCE_SUCCESS: null,
  UPDATE_CANDIDATE_PERFORMANCE_ERROR: null,
  UPDATE_CANDIDATE_PERFORMANCE_CLEAR: null,

  UPDATE_CANDIDATE_NOTES: null,
  UPDATE_CANDIDATE_NOTES_SUCCESS: null,
  UPDATE_CANDIDATE_NOTES_ERROR: null,
  UPDATE_CANDIDATE_NOTES_CLEAR: null,

  UPDATE_CANDIDATE_SMART_BOX: null,
  UPDATE_CANDIDATE_SMART_BOX_SUCCESS: null,
  UPDATE_CANDIDATE_SMART_BOX_ERROR: null,
  UPDATE_CANDIDATE_SMART_BOX_CLEAR: null,

  SEARCH_USER_LIST: null,
  SEARCH_USER_LIST_SUCCESS: null,
  SEARCH_USER_LIST_ERROR: null,
  SEARCH_USER_LIST_CLEAR: null,

  SITE_SUPPLIER_LIST: null,
  SITE_SUPPLIER_LIST_SUCCESS: null,
  SITE_SUPPLIER_LIST_ERROR: null,
  SITE_SUPPLIER_LIST_CLEAR: null,

  ADD_BOOKING: null,
  ADD_BOOKING_SUCCESS: null,
  ADD_BOOKING_ERROR: null,
  ADD_BOOKING_CLEAR: null,

  GET_BOOKING_DETAILS: null,
  GET_BOOKING_DETAILS_SUCCESS: null,
  GET_BOOKING_DETAILS_ERROR: null,
  GET_BOOKING_DETAILS_CLEAR: null,

  DELETE_CANDIDATE_RATING: null,
  DELETE_CANDIDATE_RATING_SUCCESS: null,
  DELETE_CANDIDATE_RATING_ERROR: null,
  DELETE_CANDIDATE_RATING_CLEAR: null,

  DELETE_CANDIDATE_INFRINGEMENTS: null,
  DELETE_CANDIDATE_INFRINGEMENTS_SUCCESS: null,
  DELETE_CANDIDATE_INFRINGEMENTS_ERROR: null,
  DELETE_CANDIDATE_INFRINGEMENTS_CLEAR: null,

  DELETE_CANDIDATE_NOTES: null,
  DELETE_CANDIDATE_NOTES_SUCCESS: null,
  DELETE_CANDIDATE_NOTES_ERROR: null,
  DELETE_CANDIDATE_NOTES_CLEAR: null,

  DELETE_CANDIDATE_TICKETS: null,
  DELETE_CANDIDATE_TICKETS_SUCCESS: null,
  DELETE_CANDIDATE_TICKETS_ERROR: null,
  DELETE_CANDIDATE_TICKETS_CLEAR: null,

  DELETE_CANDIDATE_PERFORMANCE: null,
  DELETE_CANDIDATE_PERFORMANCE_SUCCESS: null,
  DELETE_CANDIDATE_PERFORMANCE_ERROR: null,
  DELETE_CANDIDATE_PERFORMANCE_CLEAR: null,

  UPDATE_CANDIDATE_TICKETS: null,
  UPDATE_CANDIDATE_TICKETS_SUCCESS: null,
  UPDATE_CANDIDATE_TICKETS_ERROR: null,
  UPDATE_CANDIDATE_TICKETS_CLEAR: null,

  ADD_BOOKING_RULE: null,
  ADD_BOOKING_RULE_SUCCESS: null,
  ADD_BOOKING_RULE_ERROR: null,
  ADD_BOOKING_RULE_CLEAR: null,

  SAVE_AWR_DETAILS: null,
  SAVE_AWR_DETAILS_SUCCESS: null,
  SAVE_AWR_DETAILS_ERROR: null,
  SAVE_AWR_DETAILS_CLEAR: null,

  BOOKING_RULE_REQUIREMENTS_TYPE: null,
  BOOKING_RULE_REQUIREMENTS_TYPE_SUCCESS: null,
  BOOKING_RULE_REQUIREMENTS_TYPE_ERROR: null,
  BOOKING_RULE_REQUIREMENTS_TYPE_CLEAR: null,

  SAVE_BOOKING_RULE: null,
  SAVE_BOOKING_RULE_SUCCESS: null,
  SAVE_BOOKING_RULE_ERROR: null,
  SAVE_BOOKING_RULE_CLEAR: null,

  DEFAULT_AWR_RULE: null,
  DEFAULT_AWR_RULE_SUCCESS: null,
  DEFAULT_AWR_RULE_ERROR: null,
  DEFAULT_AWR_RULE_CLEAR: null,

  TAGS_LIST: null,
  TAGS_LIST_SUCCESS: null,
  TAGS_LIST_ERROR: null,
  TAGS_LIST_CLEAR: null,

  TAGS_LIST_V2: null,
  TAGS_LIST_V2_SUCCESS: null,
  TAGS_LIST_V2_ERROR: null,
  TAGS_LIST_V2_CLEAR: null,

  ADD_TAGS_LIST: null,
  ADD_TAGS_LIST_SUCCESS: null,
  ADD_TAGS_LIST_ERROR: null,
  ADD_TAGS_LIST_CLEAR: null,

  UPDATE_SITE_CONTACT: null,
  UPDATE_SITE_CONTACT_SUCCESS: null,
  UPDATE_SITE_CONTACT_ERROR: null,
  UPDATE_SITE_CONTACT_CLEAR: null,

  GET_SITE_DETAILS: null,
  GET_SITE_DETAILS_SUCCESS: null,
  GET_SITE_DETAILS_ERROR: null,
  GET_SITE_DETAILS_CLEAR: null,

  ADD_CLIENT_SHIFT_TYPE_DATA: null,
  ADD_CLIENT_SHIFT_TYPE_DATA_SUCCESS: null,
  ADD_CLIENT_SHIFT_TYPE_DATA_ERROR: null,
  ADD_CLIENT_SHIFT_TYPE_DATA_CLEAR: null,

  UPDATE_CLIENT_SHIFT_TYPE_DATA: null,
  UPDATE_CLIENT_SHIFT_TYPE_DATA_SUCCESS: null,
  UPDATE_CLIENT_SHIFT_TYPE_DATA_ERROR: null,
  UPDATE_CLIENT_SHIFT_TYPE_DATA_CLEAR: null,

  DELETE_CLIENT_SHIFT_TYPE_DATA: null,
  DELETE_CLIENT_SHIFT_TYPE_DATA_SUCCESS: null,
  DELETE_CLIENT_SHIFT_TYPE_DATA_ERROR: null,
  DELETE_CLIENT_SHIFT_TYPE_DATA_CLEAR: null,

  UPDATE_GENERAL_DETAILS: null,
  UPDATE_GENERAL_DETAILS_SUCCESS: null,
  UPDATE_GENERAL_DETAILS_ERROR: null,
  UPDATE_GENERAL_DETAILS_CLEAR: null,

  GET_SITE_CONTACT_DETAILS: null,
  GET_SITE_CONTACT_DETAILS_SUCCESS: null,
  GET_SITE_CONTACT_DETAILS_ERROR: null,
  GET_SITE_CONTACT_DETAILS_CLEAR: null,

  CANDIDATE_DETAILS: null,
  CANDIDATE_DETAILS_SUCCESS: null,
  CANDIDATE_DETAILS_ERROR: null,
  CANDIDATE_DETAILS_CLEAR: null,

  REQUEST_LICENCE_CHECK: null,
  REQUEST_LICENCE_CHECK_SUCCESS: null,
  REQUEST_LICENCE_CHECK_ERROR: null,
  REQUEST_LICENCE_CHECK_CLEAR: null,

  UPDATE_CANDIDATE_DETAILS: null,
  UPDATE_CANDIDATE_DETAILS_SUCCESS: null,
  UPDATE_CANDIDATE_DETAILS_ERROR: null,
  UPDATE_CANDIDATE_DETAILS_CLEAR: null,

  CONTACT_DETAILS: null,
  CONTACT_DETAILS_SUCCESS: null,
  CONTACT_DETAILS_ERROR: null,
  CONTACT_DETAILS_CLEAR: null,

  UPDATE_CONTACT_DETAILS: null,
  UPDATE_CONTACT_DETAILS_SUCCESS: null,
  UPDATE_CONTACT_DETAILS_ERROR: null,
  UPDATE_CONTACT_DETAILS_CLEAR: null,

  ADDITIONAL_CONTACT_DETAILS: null,
  ADDITIONAL_CONTACT_DETAILS_SUCCESS: null,
  ADDITIONAL_CONTACT_DETAILS_ERROR: null,
  ADDITIONAL_CONTACT_DETAILS_CLEAR: null,

  UPDATE_ADDITIONAL_CONTACT: null,
  UPDATE_ADDITIONAL_CONTACT_SUCCESS: null,
  UPDATE_ADDITIONAL_CONTACT_ERROR: null,
  UPDATE_ADDITIONAL_CONTACT_CLEAR: null,

  GET_WORK_HISTORY_DETAILS: null,
  GET_WORK_HISTORY_DETAILS_SUCCESS: null,
  GET_WORK_HISTORY_DETAILS_ERROR: null,
  GET_WORK_HISTORY_DETAILS_CLEAR: null,

  GET_START_END_DATES: null,
  GET_START_END_DATES_SUCCESS: null,
  GET_START_END_DATES_ERROR: null,
  GET_START_END_DATES_CLEAR: null,

  DELETE_ADDRESS_DETAILS: null,
  DELETE_ADDRESS_DETAILS_SUCCESS: null,
  DELETE_ADDRESS_DETAILS_ERROR: null,
  DELETE_ADDRESS_DETAILS_CLEAR: null,

  UPDATE_WORK_HISTORY: null,
  UPDATE_WORK_HISTORY_SUCCESS: null,
  UPDATE_WORK_HISTORY_ERROR: null,
  UPDATE_WORK_HISTORY_CLEAR: null,

  DELETE_WORK_HISTORY: null,
  DELETE_WORK_HISTORY_SUCCESS: null,
  DELETE_WORK_HISTORY_ERROR: null,
  DELETE_WORK_HISTORY_CLEAR: null,

  DELETE_START_END_DATE: null,
  DELETE_START_END_DATE_SUCCESS: null,
  DELETE_START_END_DATE_ERROR: null,
  DELETE_START_END_DATE_CLEAR: null,

  UPDATE_START_END_DATE: null,
  UPDATE_START_END_DATE_SUCCESS: null,
  UPDATE_START_END_DATE_ERROR: null,
  UPDATE_START_END_DATE_CLEAR: null,

  GET_INDUCTIONS_BANS_LIST: null,
  GET_INDUCTIONS_BANS_LIST_SUCCESS: null,
  GET_INDUCTIONS_BANS_LIST_ERROR: null,
  GET_INDUCTIONS_BANS_LIST_CLEAR: null,

  UPDATE_INDUCTIONS_BANS: null,
  UPDATE_INDUCTIONS_BANS_SUCCESS: null,
  UPDATE_INDUCTIONS_BANS_ERROR: null,
  UPDATE_INDUCTIONS_BANS_CLEAR: null,

  DELETE_INDUCTIONS_DETAILS: null,
  DELETE_INDUCTIONS_DETAILS_SUCCESS: null,
  DELETE_INDUCTIONS_DETAILS_ERROR: null,
  DELETE_INDUCTIONS_DETAILS_CLEAR: null,

  DELETE_BANS_DETAILS: null,
  DELETE_BANS_DETAILS_SUCCESS: null,
  DELETE_BANS_DETAILS_ERROR: null,
  DELETE_BANS_DETAILS_CLEAR: null,

  DELETE_AWR_RULE: null,
  DELETE_AWR_RULE_SUCCESS: null,
  DELETE_AWR_RULE_ERROR: null,
  DELETE_AWR_RULE_CLEAR: null,

  COPY_AWR_RULE: null,
  COPY_AWR_RULE_SUCCESS: null,
  COPY_AWR_RULE_ERROR: null,
  COPY_AWR_RULE_CLEAR: null,

  GET_BOOKING_RULE: null,
  GET_BOOKING_RULE_SUCCESS: null,
  GET_BOOKING_RULE_ERROR: null,
  GET_BOOKING_RULE_CLEAR: null,

  GET_RESOURCE_REQUIREMENT: null,
  GET_RESOURCE_REQUIREMENT_SUCCESS: null,
  GET_RESOURCE_REQUIREMENT_ERROR: null,
  GET_RESOURCE_REQUIREMENT_CLEAR: null,

  SAVE_RESOURCE_REQUIREMENT: null,
  SAVE_RESOURCE_REQUIREMENT_SUCCESS: null,
  SAVE_RESOURCE_REQUIREMENT_ERROR: null,
  SAVE_RESOURCE_REQUIREMENT_CLEAR: null,

  UPDATE_RESOURCE_REQUIREMENT: null,
  UPDATE_RESOURCE_REQUIREMENT_SUCCESS: null,
  UPDATE_RESOURCE_REQUIREMENT_ERROR: null,
  UPDATE_RESOURCE_REQUIREMENT_CLEAR: null,

  DELETE_RESOURCE_REQUIREMENT: null,
  DELETE_RESOURCE_REQUIREMENT_SUCCESS: null,
  DELETE_RESOURCE_REQUIREMENT_ERROR: null,
  DELETE_RESOURCE_REQUIREMENT_CLEAR: null,

  GET_PIPELINE_CANDIDATE: null,
  GET_PIPELINE_CANDIDATE_SUCCESS: null,
  GET_PIPELINE_CANDIDATE_ERROR: null,
  GET_PIPELINE_CANDIDATE_CLEAR: null,

  GET_SITE_CANDIDATE_PERFORMANCE: null,
  GET_SITE_CANDIDATE_PERFORMANCE_SUCCESS: null,
  GET_SITE_CANDIDATE_PERFORMANCE_ERROR: null,
  GET_SITE_CANDIDATE_PERFORMANCE_CLEAR: null,

  GET_SITE_INFRINGEMENT: null,
  GET_SITE_INFRINGEMENT_SUCCESS: null,
  GET_SITE_INFRINGEMENT_ERROR: null,
  GET_SITE_INFRINGEMENT_CLEAR: null,

  GET_TIME_PREFERENCE: null,
  GET_TIME_PREFERENCE_SUCCESS: null,
  GET_TIME_PREFERENCE_ERROR: null,
  GET_TIME_PREFERENCE_CLEAR: null,

  GET_PAYROLL_DETAILS: null,
  GET_PAYROLL_DETAILS_SUCCESS: null,
  GET_PAYROLL_DETAILS_ERROR: null,
  GET_PAYROLL_DETAILS_CLEAR: null,

  GET_IS_CANDIDATE_STS: null,
  GET_IS_CANDIDATE_STS_SUCCESS: null,
  GET_IS_CANDIDATE_STS_ERROR: null,
  GET_IS_CANDIDATE_STS_CLEAR: null,

  UPDATE_PAYROLL_DETAILS: null,
  UPDATE_PAYROLL_DETAILS_SUCCESS: null,
  UPDATE_PAYROLL_DETAILS_ERROR: null,
  UPDATE_PAYROLL_DETAILS_CLEAR: null,

  ADD_DRIVING_LICENSE: null,
  ADD_DRIVING_LICENSE_SUCCESS: null,
  ADD_DRIVING_LICENSE_ERROR: null,
  ADD_DRIVING_LICENSE_CLEAR: null,

  ADD_DRIVER_LICENSE: null,
  ADD_DRIVER_LICENSE_SUCCESS: null,
  ADD_DRIVER_LICENSE_ERROR: null,
  ADD_DRIVER_LICENSE_CLEAR: null,

  GET_DRIVING_LICENSE: null,
  GET_DRIVING_LICENSE_SUCCESS: null,
  GET_DRIVING_LICENSE_ERROR: null,
  GET_DRIVING_LICENSE_CLEAR: null,

  GET_HUMAN_RESOURCE: null,
  GET_HUMAN_RESOURCE_SUCCESS: null,
  GET_HUMAN_RESOURCE_ERROR: null,
  GET_HUMAN_RESOURCE_CLEAR: null,

  UPDATE_HUMAN_RESOURCE: null,
  UPDATE_HUMAN_RESOURCE_SUCCESS: null,
  UPDATE_HUMAN_RESOURCE_ERROR: null,
  UPDATE_HUMAN_RESOURCE_CLEAR: null,

  ADD_LICENCE_CAPABILITIES: null,
  ADD_LICENCE_CAPABILITIES_SUCCESS: null,
  ADD_LICENCE_CAPABILITIES_ERROR: null,
  ADD_LICENCE_CAPABILITIES_CLEAR: null,

  UPDATE_LICENCE_CAPABILITIES: null,
  UPDATE_LICENCE_CAPABILITIES_SUCCESS: null,
  UPDATE_LICENCE_CAPABILITIES_ERROR: null,
  UPDATE_LICENCE_CAPABILITIES_CLEAR: null,

  ADD_LICENCE_ENDORSEMENTS: null,
  ADD_LICENCE_ENDORSEMENTS_SUCCESS: null,
  ADD_LICENCE_ENDORSEMENTS_ERROR: null,
  ADD_LICENCE_ENDORSEMENTS_CLEAR: null,

  UPDATE_LICENCE_ENDORSEMENTS: null,
  UPDATE_LICENCE_ENDORSEMENTS_SUCCESS: null,
  UPDATE_LICENCE_ENDORSEMENTS_ERROR: null,
  UPDATE_LICENCE_ENDORSEMENTS_CLEAR: null,

  DELETE_LICENCE_ENDORSEMENTS: null,
  DELETE_LICENCE_ENDORSEMENTS_SUCCESS: null,
  DELETE_LICENCE_ENDORSEMENTS_ERROR: null,
  DELETE_LICENCE_ENDORSEMENTS_CLEAR: null,

  DELETE_LICENCE_CAPABILITIES: null,
  DELETE_LICENCE_CAPABILITIES_SUCCESS: null,
  DELETE_LICENCE_CAPABILITIES_ERROR: null,
  DELETE_LICENCE_CAPABILITIES_CLEAR: null,

  ADD_DIGI_CARD: null,
  ADD_DIGI_CARD_SUCCESS: null,
  ADD_DIGI_CARD_ERROR: null,
  ADD_DIGI_CARD_CLEAR: null,

  COST_ANALYSIS_REPORT: null,
  COST_ANALYSIS_REPORT_SUCCESS: null,
  COST_ANALYSIS_REPORT_ERROR: null,
  COST_ANALYSIS_REPORT_CLEAR: null,

  KPI_REPORT: null,
  KPI_REPORT_SUCCESS: null,
  KPI_REPORT_ERROR: null,
  KPI_REPORT_CLEAR: null,

  DELETE_DIGI_CARD: null,
  DELETE_DIGI_CARD_SUCCESS: null,
  DELETE_DIGI_CARD_ERROR: null,
  DELETE_DIGI_CARD_CLEAR: null,

  UPDATE_DIGI_CARD: null,
  UPDATE_DIGI_CARD_SUCCESS: null,
  UPDATE_DIGI_CARD_ERROR: null,
  UPDATE_DIGI_CARD_CLEAR: null,

  GET_DIGI_CARD: null,
  GET_DIGI_CARD_SUCCESS: null,
  GET_DIGI_CARD_ERROR: null,
  GET_DIGI_CARD_CLEAR: null,

  GET_QUALIFICATION_DETAILS: null,
  GET_QUALIFICATION_DETAILS_SUCCESS: null,
  GET_QUALIFICATION_DETAILS_ERROR: null,
  GET_QUALIFICATION_DETAILS_CLEAR: null,

  DELETE_QUALIFICATION_DETAILS: null,
  DELETE_QUALIFICATION_DETAILS_SUCCESS: null,
  DELETE_QUALIFICATION_DETAILS_ERROR: null,
  DELETE_QUALIFICATION_DETAILS_CLEAR: null,

  UPDATE_QUALIFICATION_DETAILS: null,
  UPDATE_QUALIFICATION_DETAILS_SUCCESS: null,
  UPDATE_QUALIFICATION_DETAILS_ERROR: null,
  UPDATE_QUALIFICATION_DETAILS_CLEAR: null,

  CLIENT_INFRIGEMENT: null,
  CLIENT_INFRIGEMENT_SUCCESS: null,
  CLIENT_INFRIGEMENT_ERROR: null,
  CLIENT_INFRIGEMENT_CLEAR: null,

  CLIENT_PERFORMANCE: null,
  CLIENT_PERFORMANCE_SUCCESS: null,
  CLIENT_PERFORMANCE_ERROR: null,
  CLIENT_PERFORMANCE_CLEAR: null,

  CLIENT_INFRIGEMENT_SIGN: null,
  CLIENT_INFRIGEMENT_SIGN_SUCCESS: null,
  CLIENT_INFRIGEMENT_SIGN_ERROR: null,
  CLIENT_INFRIGEMENT_SIGN_CLEAR: null,

  CLIENT_PERFORMANCE_CLOSE: null,
  CLIENT_PERFORMANCE_CLOSE_SUCCESS: null,
  CLIENT_PERFORMANCE_CLOSE_ERROR: null,
  CLIENT_PERFORMANCE_CLOSE_CLEAR: null,

  REMINDERS_LIST: null,
  REMINDERS_LIST_SUCCESS: null,
  REMINDERS_LIST_ERROR: null,
  REMINDERS_LIST_CLEAR: null,

  DELETE_REMINDER: null,
  DELETE_REMINDER_SUCCESS: null,
  DELETE_REMINDER_ERROR: null,
  DELETE_REMINDER_CLEAR: null,

  GET_ASSIGNED_TICKETS: null,
  GET_ASSIGNED_TICKETS_SUCCESS: null,
  GET_ASSIGNED_TICKETS_ERROR: null,
  GET_ASSIGNED_TICKETS_CLEAR: null,

  PENDING_DOCUMENT_LIST: null,
  PENDING_DOCUMENT_LIST_SUCCESS: null,
  PENDING_DOCUMENT_LIST_ERROR: null,
  PENDING_DOCUMENT_LIST_CLEAR: null,

  PAY_DOCUMENT_LIST: null,
  PAY_DOCUMENT_LIST_SUCCESS: null,
  PAY_DOCUMENT_LIST_ERROR: null,
  PAY_DOCUMENT_LIST_CLEAR: null,

  HOLD_DOCUMENT: null,
  HOLD_DOCUMENT_SUCCESS: null,
  HOLD_DOCUMENT_ERROR: null,
  HOLD_DOCUMENT_CLEAR: null,

  UNHOLD_DOCUMENT: null,
  UNHOLD_DOCUMENT_SUCCESS: null,
  UNHOLD_DOCUMENT_ERROR: null,
  UNHOLD_DOCUMENT_CLEAR: null,

  DELETE_PENDING_DOCUMENT: null,
  DELETE_PENDING_DOCUMENT_SUCCESS: null,
  DELETE_PENDING_DOCUMENT_ERROR: null,
  DELETE_PENDING_DOCUMENT_CLEAR: null,

  SEND_TO_XERO: null,
  SEND_TO_XERO_SUCCESS: null,
  SEND_TO_XERO_ERROR: null,
  SEND_TO_XERO_CLEAR: null,

  MODIFY_PAY_DOCUMENT: null,
  MODIFY_PAY_DOCUMENT_SUCCESS: null,
  MODIFY_PAY_DOCUMENT_ERROR: null,
  MODIFY_PAY_DOCUMENT_CLEAR: null,

  VIEW_DOCUMENT: null,
  VIEW_DOCUMENT_SUCCESS: null,
  VIEW_DOCUMENT_ERROR: null,
  VIEW_DOCUMENT_CLEAR: null,

  PAY_RATE_LISTING: null,
  PAY_RATE_SUCCESS: null,
  PAY_RATE_LISTING_ERROR: null,
  PAY_RATE_CLEAR: null,

  HOLIDAY_LISTING: null,
  HOLIDAY_LISTING_SUCCESS: null,
  HOLIDAY_LISTING_ERROR: null,
  HOLIDAY_LISTING_CLEAR: null,

  DELETE_MULTIPLE_HOLIDAYS: null,
  DELETE_MULTIPLE_HOLIDAYS_SUCCESS: null,
  DELETE_MULTIPLE_HOLIDAYS_ERROR: null,
  DELETE_MULTIPLE_HOLIDAYS_CLEAR: null,

  ADD_ADJUSTMENT: null,
  ADD_ADJUSTMENT_SUCCESS: null,
  ADD_ADJUSTMENT_ERROR: null,
  ADD_ADJUSTMENT_CLEAR: null,

  UPDATE_PAY_DOCUMENT: null,
  UPDATE_PAY_DOCUMENT_SUCCESS: null,
  UPDATE_PAY_DOCUMENT_ERROR: null,
  UPDATE_PAY_DOCUMENT_CLEAR: null,

  DELETE_ADJUSTMENT: null,
  DELETE_ADJUSTMENT_SUCCESS: null,
  DELETE_ADJUSTMENT_ERROR: null,
  DELETE_ADJUSTMENT_CLEAR: null,

  UPDATE_ADJUSTMENT: null,
  UPDATE_ADJUSTMENT_SUCCESS: null,
  UPDATE_ADJUSTMENT_ERROR: null,
  UPDATE_ADJUSTMENT_CLEAR: null,

  CREATE_PAY_DOCUMENT: null,
  CREATE_PAY_DOCUMENT_SUCCESS: null,
  CREATE_PAY_DOCUMENT_ERROR: null,
  CREATE_PAY_DOCUMENT_CLEAR: null,

  PRINT_PAY_DOCUMENT: null,
  PRINT_PAY_DOCUMENT_SUCCESS: null,
  PRINT_PAY_DOCUMENT_ERROR: null,
  PRINT_PAY_DOCUMENT_CLEAR: null,

  DELETE_PAY_RATE: null,
  DELETE_PAY_RATE_SUCCESS: null,
  DELETE_PAY_RATE_ERROR: null,
  DELETE_PAY_RATE_CLEAR: null,

  BUILD_PAY_DOCUMENTS: null,
  BUILD_PAY_DOCUMENTS_SUCCESS: null,
  BUILD_PAY_DOCUMENTS_ERROR: null,
  BUILD_PAY_DOCUMENTS_CLEAR: null,

  ADD_PAY_RATE_RULES: null,
  ADD_PAY_RATE_RULES_SUCCESS: null,
  ADD_PAY_RATE_RULES_ERROR: null,
  ADD_PAY_RATE_RULES_CLEAR: null,

  ADD_RATE_CARD: null,
  ADD_RATE_CARD_SUCCESS: null,
  ADD_RATE_CARD_ERROR: null,
  ADD_RATE_CARD_CLEAR: null,

  UPDATE_PAY_RATE_RULES: null,
  UPDATE_PAY_RATE_RULES_SUCCESS: null,
  UPDATE_PAY_RATE_RULES_ERROR: null,
  UPDATE_PAY_RATE_RULES_CLEAR: null,

  UPDATE_RATE_CARD: null,
  UPDATE_RATE_CARD_SUCCESS: null,
  UPDATE_RATE_CARD_ERROR: null,
  UPDATE_RATE_CARD_CLEAR: null,

  DELETE_RATE_CARD: null,
  DELETE_RATE_CARD_SUCCESS: null,
  DELETE_RATE_CARD_ERROR: null,
  DELETE_RATE_CARD_CLEAR: null,

  VIEW_PAY_RATE: null,
  VIEW_PAY_RATE_SUCCESS: null,
  VIEW_PAY_RATE_ERROR: null,
  VIEW_PAY_RATE_CLEAR: null,

  BUILD_PAY_DOCUMENTS_PROGRESS: null,
  BUILD_PAY_DOCUMENTS_PROGRESS_SUCCESS: null,
  BUILD_PAY_DOCUMENTS_PROGRESS_ERROR: null,
  BUILD_PAY_DOCUMENTS_PROGRESS_CLEAR: null,

  VIEW_PAY_RATE_HISTORY: null,
  VIEW_PAY_RATE_HISTORY_SUCCESS: null,
  VIEW_PAY_RATE_HISTORY_ERROR: null,
  VIEW_PAY_RATE_HISTORY_CLEAR: null,

  COPY_PAY_RATE: null,
  COPY_PAY_RATE_SUCCESS: null,
  COPY_PAY_RATE_ERROR: null,
  COPY_PAY_RATE_CLEAR: null,

  GET_USER_CLIENT_LIST: null,
  GET_USER_CLIENT_LIST_SUCCESS: null,
  GET_USER_CLIENT_LIST_ERROR: null,
  GET_USER_CLIENT_LIST_CLEAR: null,

  GET_VERIFY_BOOKING_LIST: null,
  GET_VERIFY_BOOKING_LIST_SUCCESS: null,
  GET_VERIFY_BOOKING_LIST_ERROR: null,
  GET_VERIFY_BOOKING_LIST_CLEAR: null,

  GET_PAYE_LIST: null,
  GET_PAYE_LIST_SUCCESS: null,
  GET_PAYE_LIST_ERROR: null,
  GET_PAYE_LIST_CLEAR: null,

  GET_SITE_DOCUMENT: null,
  GET_SITE_DOCUMENT_SUCCESS: null,
  GET_SITE_DOCUMENT_ERROR: null,
  GET_SITE_DOCUMENT_CLEAR: null,

  DELETE_SITE_DOCUMENT: null,
  DELETE_SITE_DOCUMENT_SUCCESS: null,
  DELETE_SITE_DOCUMENT_ERROR: null,
  DELETE_SITE_DOCUMENT_CLEAR: null,

  DELETE_USER_TAGS: null,
  DELETE_USER_TAGS_SUCCESS: null,
  DELETE_USER_TAGS_ERROR: null,
  DELETE_USER_TAGS_CLEAR: null,

  LIST_ATTACHMENTS: null,
  LIST_ATTACHMENTS_SUCCESS: null,
  LIST_ATTACHMENTS_ERROR: null,
  LIST_ATTACHMENTS_CLEAR: null,

  DOWNLOAD_DOCUMENT: null,
  DOWNLOAD_DOCUMENT_SUCCESS: null,
  DOWNLOAD_DOCUMENT_ERROR: null,
  DOWNLOAD_DOCUMENT_CLEAR: null,

  DELETE_DOCUMENT: null,
  DELETE_DOCUMENT_SUCCESS: null,
  DELETE_DOCUMENT_ERROR: null,
  DELETE_DOCUMENT_CLEAR: null,

  DELETE_SMART_BOX: null,
  DELETE_SMART_BOX_SUCCESS: null,
  DELETE_SMART_BOX_ERROR: null,
  DELETE_SMART_BOX_CLEAR: null,

  UPDATE_SITE_INFRINGEMENT: null,
  UPDATE_SITE_INFRINGEMENT_SUCCESS: null,
  UPDATE_SITE_INFRINGEMENT_ERROR: null,
  UPDATE_SITE_INFRINGEMENT_CLEAR: null,

  REFRESH_META_CACHE: null,
  REFRESH_META_CACHE_SUCCESS: null,
  REFRESH_META_CACHE_ERROR: null,
  REFRESH_META_CACHE_CLEAR: null,

  ADD_CLIENT_INFO: null,
  ADD_CLIENT_INFO_SUCCESS: null,
  ADD_CLIENT_INFO_ERROR: null,
  ADD_CLIENT_INFO_CLEAR: null,

  UPDATE_CLIENT_INFO: null,
  UPDATE_CLIENT_INFO_SUCCESS: null,
  UPDATE_CLIENT_INFO_ERROR: null,
  UPDATE_CLIENT_INFO_CLEAR: null,

  DELETE_CLIENT_INFO: null,
  DELETE_CLIENT_INFO_SUCCESS: null,
  DELETE_CLIENT_INFO_ERROR: null,
  DELETE_CLIENT_INFO_CLEAR: null,

  SEND_APP_SMS: null,
  SEND_APP_SMS_SUCCESS: null,
  SEND_APP_SMS_ERROR: null,
  SEND_APP_SMS_CLEAR: null,

  APP_HISTORY_LIST: null,
  APP_HISTORY_LIST_SUCCESS: null,
  APP_HISTORY_LIST_ERROR: null,
  APP_HISTORY_LIST_CLEAR: null,

  REGISTER_APP_PERSON: null,
  REGISTER_APP_PERSON_SUCCESS: null,
  REGISTER_APP_PERSON_ERROR: null,
  REGISTER_APP_PERSON_CLEAR: null,

  DETAIL_PERSON_SEARCH: null,
  DETAIL_PERSON_SEARCH_SUCCESS: null,
  DETAIL_PERSON_SEARCH_ERROR: null,
  DETAIL_PERSON_SEARCH_CLEAR: null,

  CONFIRM_TIME_OFF: null,
  CONFIRM_TIME_OFF_SUCCESS: null,
  CONFIRM_TIME_OFF_ERROR: null,
  CONFIRM_TIME_OFF_CLEAR: null,

  HOURS_WORKED_IMPORT: null,
  HOURS_WORKED_IMPORT_SUCCESS: null,
  HOURS_WORKED_IMPORT_ERROR: null,
  HOURS_WORKED_IMPORT_CLEAR: null,

  ADD_CANDIDATE_IMPORT: null,
  ADD_CANDIDATE_IMPORT_SUCCESS: null,
  ADD_CANDIDATE_IMPORT_ERROR: null,
  ADD_CANDIDATE_IMPORT_CLEAR: null,

  UPDATE_PERSON_NOTE: null,
  UPDATE_PERSON_NOTE_SUCCESS: null,
  UPDATE_PERSON_NOTE_ERROR: null,
  UPDATE_PERSON_NOTE_CLEAR: null,

  LIST_REPORTS: null,
  LIST_REPORTS_SUCCESS: null,
  LIST_REPORTS_ERROR: null,
  LIST_REPORTS_CLEAR: null,

  GET_REPORTS_INFO: null,
  GET_REPORTS_INFO_SUCCESS: null,
  GET_REPORTS_INFO_ERROR: null,
  GET_REPORTS_INFO_CLEAR: null,

  RUN_REPORT: null,
  RUN_REPORT_SUCCESS: null,
  RUN_REPORT_ERROR: null,
  RUN_REPORT_CLEAR: null,

  SEND_BULK_SMS: null,
  SEND_BULK_SMS_SUCCESS: null,
  SEND_BULK_SMS_ERROR: null,
  SEND_BULK_SMS_CLEAR: null,

  SEND_AVAILABILITY_REQUEST: null,
  SEND_AVAILABILITY_REQUEST_SUCCESS: null,
  SEND_AVAILABILITY_REQUEST_ERROR: null,
  SEND_AVAILABILITY_REQUEST_CLEAR: null,

  SEND_CALENDAR_UPDATED: null,
  SEND_CALENDAR_UPDATED_SUCCESS: null,
  SEND_CALENDAR_UPDATED_ERROR: null,
  SEND_CALENDAR_UPDATED_CLEAR: null,

  SEND_NO_WORK_TOMORROW: null,
  SEND_NO_WORK_TOMORROW_SUCCESS: null,
  SEND_NO_WORK_TOMORROW_ERROR: null,
  SEND_NO_WORK_TOMORROW_CLEAR: null,

  SEND_CALENDAR_SUMMARY: null,
  SEND_CALENDAR_SUMMARY_SUCCESS: null,
  SEND_CALENDAR_SUMMARY_ERROR: null,
  SEND_CALENDAR_SUMMARY_CLEAR: null,

  SEND_BOOKING_DETAILS: null,
  SEND_BOOKING_DETAILS_SUCCESS: null,
  SEND_BOOKING_DETAILS_ERROR: null,
  SEND_BOOKING_DETAILS_CLEAR: null,

  SEND_BOOKING_HRS: null,
  SEND_BOOKING_HRS_SUCCESS: null,
  SEND_BOOKING_HRS_ERROR: null,
  SEND_BOOKING_HRS_CLEAR: null,

  SEND_SMS_TO_PHONE_NUMBER: null,
  SEND_SMS_TO_PHONE_NUMBER_SUCCESS: null,
  SEND_SMS_TO_PHONE_NUMBER_ERROR: null,
  SEND_SMS_TO_PHONE_NUMBER_CLEAR: null,

  GET_CANDIDATE_LINKS: null,
  GET_CANDIDATE_LINKS_SUCCESS: null,
  GET_CANDIDATE_LINKS_ERROR: null,
  GET_CANDIDATE_LINKS_CLEAR: null,

  HOLIDAYS_SHIFT_LIST: null,
  HOLIDAYS_SHIFT_LIST_SUCCESS: null,
  HOLIDAYS_SHIFT_LIST_ERROR: null,
  HOLIDAYS_SHIFT_LIST_CLEAR: null,

  HOLIDAYS_ADJUSTMENT_LIST: null,
  HOLIDAYS_ADJUSTMENT_LIST_SUCCESS: null,
  HOLIDAYS_ADJUSTMENT_LIST_ERROR: null,
  HOLIDAYS_ADJUSTMENT_LIST_CLEAR: null,

  ADD_HOLIDAYS_ADJUSTMENT_LIST: null,
  ADD_HOLIDAYS_ADJUSTMENT_LIST_SUCCESS: null,
  ADD_HOLIDAYS_ADJUSTMENT_LIST_ERROR: null,
  ADD_HOLIDAYS_ADJUSTMENT_LIST_CLEAR: null,

  ADD_HOLIDAYS_SHIFT_LIST: null,
  ADD_HOLIDAYS_SHIFT_LIST_SUCCESS: null,
  ADD_HOLIDAYS_SHIFT_LIST_ERROR: null,
  ADD_HOLIDAYS_SHIFT_LIST_CLEAR: null,

  DELETE_HOLIDAYS_ADJUSTMENT_LIST: null,
  DELETE_HOLIDAYS_ADJUSTMENT_LIST_SUCCESS: null,
  DELETE_HOLIDAYS_ADJUSTMENT_LIST_ERROR: null,
  DELETE_HOLIDAYS_ADJUSTMENT_LIST_CLEAR: null,

  DELETE_HOLIDAYS_SHIFT_LIST: null,
  DELETE_HOLIDAYS_SHIFT_LIST_SUCCESS: null,
  DELETE_HOLIDAYS_SHIFT_LIST_ERROR: null,
  DELETE_HOLIDAYS_SHIFT_LIST_CLEAR: null,

  REQUEST_RTW_CHECK: null,
  REQUEST_RTW_CHECK_SUCCESS: null,
  REQUEST_RTW_CHECK_ERROR: null,
  REQUEST_RTW_CHECK_CLEAR: null,

  ADD_PERSON_TO_LBAPI: null,
  ADD_PERSON_TO_LBAPI_SUCCESS: null,
  ADD_PERSON_TO_LBAPI_ERROR: null,
  ADD_PERSON_TO_LBAPI_CLEAR: null,

  HOLIDAY_PERIOD: null,
  HOLIDAY_PERIOD_SUCCESS: null,
  HOLIDAY_PERIOD_ERROR: null,
  HOLIDAY_PERIOD_CLEAR: null,

  DETAILED_BOOKINGS: null,
  DETAILED_BOOKINGS_SUCCESS: null,
  DETAILED_BOOKINGS_ERROR: null,
  DETAILED_BOOKINGS_CLEAR: null,

  CANDIDATE_DEFAULT_EXPENSE: null,
  CANDIDATE_DEFAULT_EXPENSE_SUCCESS: null,
  CANDIDATE_DEFAULT_EXPENSE_ERROR: null,
  CANDIDATE_DEFAULT_EXPENSE_CLEAR: null,

  ACTION_CANDIDATE_DEFAULT_EXPENSE: null,
  ACTION_CANDIDATE_DEFAULT_EXPENSE_SUCCESS: null,
  ACTION_CANDIDATE_DEFAULT_EXPENSE_ERROR: null,
  ACTION_CANDIDATE_DEFAULT_EXPENSE_CLEAR: null,

  PASSWORD_SETUP: null,
  PASSWORD_SETUP_SUCCESS: null,
  PASSWORD_SETUP_ERROR: null,
  PASSWORD_SETUP_CLEAR: null,

  GET_APP_DETAILS: null,
  GET_APP_DETAILS_SUCCESS: null,
  GET_APP_DETAILS_ERROR: null,
  GET_APP_DETAILS_CLEAR: null,

  CLAIM_APP_ID: null,
  CLAIM_APP_ID_SUCCESS: null,
  CLAIM_APP_ID_ERROR: null,
  CLAIM_APP_ID_CLEAR: null,

  CLEAR_APP_PROFILE: null,
  CLEAR_APP_PROFILE_SUCCESS: null,
  CLEAR_APP_PROFILE_ERROR: null,
  CLEAR_APP_PROFILE_CLEAR: null,

  CANDIDATE_HISTORY: null,
  CANDIDATE_HISTORY_SUCCESS: null,
  CANDIDATE_HISTORY_ERROR: null,
  CANDIDATE_HISTORY_CLEAR: null,

  CANDIDATE_EMAIL_ALL: null,
  CANDIDATE_EMAIL_ALL_SUCCESS: null,
  CANDIDATE_EMAIL_ALL_ERROR: null,
  CANDIDATE_EMAIL_ALL_CLEAR: null,

  MERGE_DOCUMENT: null,
  MERGE_DOCUMENT_SUCCESS: null,
  MERGE_DOCUMENT_ERROR: null,
  MERGE_DOCUMENT_CLEAR: null
};

export default keyMirror(actions);
