import actionTypes from "../actionTypes";

const initialState = {
  isModalDisabled: true
};

const draggableModalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.DRAGGABLE_MODAL_STATE:
      return { ...state, isModalDisabled: payload };
    default:
      return state;
  }
};

export default draggableModalReducer;
