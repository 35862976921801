import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";

import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import { environment } from "../../config/envUrls";

const sagaMiddleware = createSagaMiddleware();
const initialState = {};

function composeReduxDevtools(props) {
  const isDev = environment === "development";

  if (isDev) {
    return composeWithDevTools(props);
  }

  return props;
}

const store = createStore(
  rootReducer,
  initialState,
  compose(composeReduxDevtools(applyMiddleware(sagaMiddleware, reduxImmutableStateInvariant())))
);

sagaMiddleware.run(rootSaga);

export default store;
